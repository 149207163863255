import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import CardList from '../components/CardList'
import Card from '../components/Card'
import CardSimple from '../components/CardSimple'
import Helmet from 'react-helmet'
import Container from '../components/Container'
import Pagination from '../components/Pagination'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'
import WeGiveATruck from '../components/WeGiveATruck'

import { Zone, Button, ZoneWrapper, CultureLayout } from '../elements'
import WeGiveATruckAd from '../components/WeGiveATruckAd'
import TagList from '../components/TagList'

const CulturePage = ({ data, pageContext }) => {
  const posts = data.allContentfulPost.edges
  const featuredPost = posts[0].node
  const { currentPage } = pageContext
  const isFirstPage = currentPage === 1

  return (
    <Layout>
      <SEO />

      {!isFirstPage && (
        <Helmet>
          <title>{`${config.siteTitle} - Page ${currentPage}`}</title>
        </Helmet>
      )}

      <Zone modifiers={['blank', 'center', 'short']} hero>
        <div className="zone-content">
          <h2>Our Priority is People</h2>
          <p>
            At D&D Transportation, we know that brand is built form the inside
            out so, we make it a priority to treat our internal team just as
            well as we do our customers. It is our internal team that makes us
            who we are, and we work hard to foster a rich and positive internal
            culture that can only result in a positive collaboration with our
            partners, customers and vendors.
          </p>
        </div>
      </Zone>

      {isFirstPage ? (
        <>
          <Container>
            <CardList>
              {posts.slice(0, 3).map(({ node: post }) => (
                <Card key={post.id} {...post} />
              ))}
            </CardList>
          </Container>
          <ZoneWrapper>
            <CultureLayout>
              <div className="left-sidebar">
                <WeGiveATruck />
              </div>
              <div className="posts">
                {posts.slice(3).map(({ node: post }) => (
                  <CardSimple key={post.id} {...post} />
                ))}
              </div>
            </CultureLayout>
          </ZoneWrapper>
        </>
      ) : (
        <Container>
          <CardList>
            {posts.map(({ node: post }) => (
              <CardSimple key={post.id} {...post} />
            ))}
          </CardList>
        </Container>
      )}

      <Pagination context={pageContext} />
    </Layout>
  )
}

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allContentfulPost(
      sort: { fields: [publishDate], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          id
          slug
          publishDate(formatString: "MMMM DD, YYYY")
          heroImage {
            title
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          body {
            childMarkdownRemark {
              html
              excerpt(pruneLength: 160)
            }
          }
        }
      }
    }
  }
`

export default CulturePage
