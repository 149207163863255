import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

import { grey, Button, LinkWrapper, Box, OverlapBoxInner } from '../elements'

const Card = ({ slug, heroImage, title, publishDate, body, ...props }) => {
  return (
    <Box featured={props.featured}>
      <LinkWrapper to={`/${slug}/`}>
        <Img fluid={heroImage.fluid} backgroundColor={'#eeeeee'} />
        <OverlapBoxInner>
          <Date>{publishDate}</Date>
          <Title>{title}</Title>
          <Excerpt
            dangerouslySetInnerHTML={{
              __html: body.childMarkdownRemark.excerpt,
            }}
          />
          <Button modifiers={['righthanging']} to={`/${slug}/`}>
            Read More&nbsp;<i className="material-icons">chevron_right</i>
          </Button>
        </OverlapBoxInner>
      </LinkWrapper>
    </Box>
  )
}

export default Card

const Title = styled.h2`
  font-size: 2em;
`
const Date = styled.h3`
  margin: 0 0 0.5rem 0;
  color: ${grey};
  text-transform: uppercase;
  font-size: 0.85em;
`
const Excerpt = styled.p`
  margin: 0 0 3rem 0;
  line-height: 1.6;
`
