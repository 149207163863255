import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { primary } from '../elements'

const List = styled.ul`
  width: 100%;
  margin: 0 auto 1em auto;
  max-width: ${props => props.theme.sizes.maxWidth};
  text-align: center;
`

const Tag = styled.li`
  display: inline-block;
  margin: 0 0.5em 0.8em 0.5em;
  a {
    display: block;
    transition: 0.2s;
    padding: 0.5em 1em;
    text-transform: capitalize;
    text-decoration: none;
    color: ${primary};
    border: 1px solid ${primary};
    border-radius: 25px;
    &:hover {
      color: white;
      background: ${primary};
    }
  }
`

const TagList = props => {
  return (
    <List>
      {props.tags.map(tag => (
        <Tag key={tag.id}>
          <Link to={`/tag/${tag.slug}/`}>{tag.title}</Link>
        </Tag>
      ))}
    </List>
  )
}

export default TagList
