import React from 'react'
import styled from 'styled-components'
import { above } from '../elements'
import WeGiveATruck from '../components/WeGiveATruck'

const WeGiveATruckAd = () => (
  <Zone>
    <WeGiveATruck />
    <div style={{ border: '1px solid red' }}>
      <h5>We Give A Truck Product 1</h5>
    </div>
    <div style={{ border: '1px solid blue' }}>
      <h5>We Give A Truck Product 2</h5>
    </div>
  </Zone>
)

export default WeGiveATruckAd

const Zone = styled.section`
  padding: 2rem;
  margin: 0 auto 2rem auto;
  position: relative;
  text-align: center;
  background: white;
  max-width: ${props => props.theme.sizes.maxWidth};
  ${above.md`
    display: flex;
    justify-content: space-between;
  `}
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  transition: background 0.2s;
  > * {
    flex: 0 0 32%;
  }
`
